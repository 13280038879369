import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Inject, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatchMediaService } from 'app/services';
import { getYear, parseISO } from 'date-fns';
import * as moment from 'moment-timezone';

@Component({
    selector: 'kui-datetime',
    templateUrl: './datetime.component.html',
    styleUrls: ['./datetime.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuiDateTimeComponent implements OnChanges, OnDestroy {

    dateInstance: Date;
    minInstance: Date;
    maxInstance: Date;
    isFocussed: boolean;

    @Input() type: 'datetime-local' | 'date' | 'time';
    @Input() format: string;
    @Input() inputStyle: 'normal' | 'none' = 'normal';
    /* expecting ISO string */
    @Input() date: string;
    @Input() max: string;
    @Input() min: string;
    @Input() disabled: boolean = undefined;
    @Input() icon: string;
    @HostBinding('class.disable-time') @Input() disableTime = false;

    @Output() onChange: EventEmitter<string> = new EventEmitter();

    id = Math.floor(Math.random() * 1e8);

    get yearRange(): string {
        const currentYear = new Date().getFullYear();
        return `${this.min ? getYear(this.min) : currentYear - 15}:${this.max ? getYear(this.max) : currentYear + 15}`;
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public matchmedia: MatchMediaService
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        const { max, min, date } = changes;

        if (date?.currentValue) {
            this.dateInstance = new Date(this.adjustDate(this.date, true));
        }

        if (max?.currentValue) {
            if (moment.isMoment(this.max)) {
                // someone gave us a moment value instead of a string value...
                this.maxInstance = parseISO((this.max as unknown as moment.Moment).toISOString());
            } else if ((this.max as any) instanceof Date) {
                this.maxInstance = this.max as unknown as Date;
            } else {
                this.maxInstance = parseISO(this.max);
            }

        }
        if (min?.currentValue) {
            if (moment.isMoment(this.min)) {
                // someone gave us a moment value instead of a string value...
                this.minInstance = parseISO((this.min as unknown as moment.Moment).toISOString());
            } else if ((this.min as any) instanceof Date) {
                this.minInstance = this.min as unknown as Date;
            } else {
                this.minInstance = parseISO(this.min);
            }
        }

    }

    ngOnDestroy() {
        this.setFocussedState(false);
    }

    update(event: string | Date) {
        const eventDate = new Date(event);
        const adjustedDate = this.adjustDate(eventDate.toISOString());
        this.onChange.emit(adjustedDate);
    }

    setFocussedState(state: boolean) {
        if (state) {
            this.document.body.classList.add('kui-datetime--open');
        } else {
            this.document.body.classList.remove('kui-datetime--open');
        }

        this.isFocussed = state;
    }

    /**
     * Gets the difference in minutes between Universal Coordinated Time (UTC) and the time on the local computer
     * 
     * This only really exists to enable mocking this call in tests...
     * 
     * @returns The Offset in minutes
     */
    getTimezoneOffset(): number {
        return new Date().getTimezoneOffset();
    }

    /**
     * The date and time the user selects in this component should be in their configured timezone.
     * The issue is that the primeng calendar component only works in the browser's timezone...
     * 
     * As an example, imagine this scenario:
     * 
     * We've been passed the value "2024-02-02T12:00:00.000Z" by the API as the value to display.
     * 
     * The user's browser is located in the SAST timezone, so +2 hours
     * The user's configured timezone is Alaska, so -9 hours
     * 
     * To get to the correct value to pass to the primeng calendar, we do the following steps:
     *
     *      API Value (UTC) --> Configured Timezone (-9 Hours) --> Local Time (+2 Hours)
     *
     * Step 1: 
     *      API Value (UTC) --> Configured Timezone (-9 Hours)
     *
     *          12:00 (UTC) --> 12:00 (-9 Hours) / 03:00 (UTC)
     *
     * Step 2:
     *      Configured Timezone (-9 Hours) --> Local Time (+2 Hours)
     *
     *          12:00 (-9 Hours) / 03:00 (UTC) --> 03:00 (+2 Hours) / 01:00 (UTC)
     *
     * 
     * We must also support doing the inverse of this process for when the user selects a date and time when using the calendar component.
     * 
     * Assuming the user selects the value 12:00 (+2), we follow the **reverse** process:
     *
     *      Local Time (+2 Hours) --> Configured Timezone (-9 Hours) --> API Value (UTC)
     *
     * Step 1: 
     *      Local Time (+2 Hours) --> Configured Timezone (-9 Hours)
     * 
     *            12:00 (+2 Hours) / 10:00 (UTC) --> 12:00 (-9 Hours) / 21:00 (UTC)
     * 
     *  Step 2:
     *       Configured Timezone (-9 Hours) --> API Value (UTC)
     * 
     *            12:00 (-9 Hours) / 21:00 (UTC) --> 21:00 (UTC)             
     * 
     * 
     * @param utcString the UTC value to convert
     * @param reverse Should we reverse the adjustment process? 
     * @returns 
     */
    private adjustDate(utcString: string, reverse = false): string {
        const momentDate = moment(utcString);

        const browserOffset = this.getTimezoneOffset();
        const timezoneOffset = momentDate.utcOffset();

        const totalOffset = browserOffset + timezoneOffset;
        
        if (reverse) {
            return momentDate.add(totalOffset, 'minutes').toISOString();
        } else {
            return momentDate.subtract(totalOffset, 'minutes').toISOString();
        }
    }
}
