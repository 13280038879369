import { Component, HostBinding } from '@angular/core';
import { BaseComponent } from 'app/shared/components';
import { TopNavService, TopNavSettings } from 'app/key-ui/top-nav';
import { MatchMediaService } from 'app/services';
import { AppService } from 'app/app.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CurrentClient } from 'app/shared/model';
import { ActivationEnd, Router } from '@angular/router';
@Component({
    selector: 'key-page-external-tab',
    templateUrl: './external-tab.component.html',
})
export class ExternalTabComponent extends BaseComponent {

    @HostBinding('class') hostClassnames = 'd-flex flex-column flex-1';
    @HostBinding('class.hidden') hidden = true;

    mobileHeader: TopNavSettings;
    path: string;
    url: SafeResourceUrl;
    tabs: { id: string, name: string, url: SafeResourceUrl, activated: boolean }[];
    active: string;


    constructor(
        private app: AppService,
        private topnav: TopNavService,
        public media: MatchMediaService,
        public router: Router,
        private sanitizer: DomSanitizer
    ) {

        super();

        // clear our tabs if the client changes
        this.on(this.app.client$, (client) => {
            if (client) {
                this.active = '';
                this.tabs = null;
                this.activateFrame(this.path);
            }
        });

        this.router.events
            .subscribe(event => {
                if (event instanceof ActivationEnd) {
                    const path = event.snapshot?.url[0]?.path;
                    this.activateFrame(path);
                }
            });
    }

    activateFrame(path: string) {
        
        if (path?.startsWith('app-')) {

            this.topnav.update(path, {});
            this.path = path;
            const client = this.app.client ? this.app.client : this.app.appStore.get<CurrentClient>('client');
            this.url = undefined;
            this.hidden = false;

            if (!this.tabs) {
                this.tabs = this.app.features.page.external.tabs.split('\n').map(str => {
                    const idx = str.indexOf(':');
                    const info = str.substr(0, idx);
                    const [title, icon, section] = info.split('|');
                    let url = str.substr(idx + 1);
                    if (section) {
                        // substitute some values internal values if they are present in the url
                        url = url
                            .replace('{token}', this.app.api.accessToken)
                            .replace('{language}', this.app.user.language || 'en-us')
                            .replace('{user}', this.app.user.id)
                            .replace('{client}', client.id);

                        return {
                            id: 'app-' + title.replace(/[^a-z0-9]/ig, '-').toLowerCase(),
                            name: title,
                            section,
                            url: this.sanitizer.bypassSecurityTrustResourceUrl(url),
                            activated: false,
                            icon,
                        };
                    }
                    return null;
                }).filter(x => x);
            }

            const tab = this.tabs.find(x => x.id === path);
            if (tab) {
                this.active = path;
                this.url = tab.url;
                tab.activated = true;
                return;
            }

        } 
        this.hidden = true;
        

    }


}
